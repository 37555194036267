import React from "react";
import styled from "styled-components";
//
import NavLink from "./navLink";
//
export default ({ mobileActive, onClick, items, title, ...rest }) => (
  <Wrap mobileActive={mobileActive}>
    <List>
      {items.map((item) => (
        <NavLink
          key={item.id}
          {...item}
          mobileActive={mobileActive}
          onClick={onClick}
        />
      ))}
    </List>
  </Wrap>
);
//

const Wrap = styled.nav`
  // margin 0 auto;
`;
const List = styled.ul`
  // display: flex;
  // align-items: stretch; /* Default */
  // justify-content: space-between;
  // width: 200px;
  margin: 0;
  padding: 0;
`;
