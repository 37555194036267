import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
//
// this is only active when the location pathname is exactly
// the same as the href.
const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null;
};

const ExactNavLink = (props) => <Link getProps={isActive} {...props} />;

// this link will be active when itself or deeper routes
// are current
const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: "active" } : null;
};

const PartialNavLink = (props) => (
  <Link getProps={isPartiallyActive} {...props} />
);

export default ({ path, title, ...rest }) => (
  <Wrap>
    <PartialNavLink to={path} title={title}>
      <h2>{title}</h2>
    </PartialNavLink>
  </Wrap>
);
//
const Wrap = styled.li`
  display: inline-block;
  margin: 0;

  a {
    display: block;
    padding-right: 0.5rem;
  }
`;
