import React from "react";
import { Root } from "react-static";
import styled from "styled-components";
//
import Meta from "containers/meta";
//
import Header from "containers/header";
//
export default ({ children, ...rest }) => (
  <Root>
    <Meta />
    <Header {...rest} />
    <Wrap>{children}</Wrap>
  </Root>
);
//
const Wrap = styled.div``;
