import React from "react";
import { Toggle } from "react-powerplug";
import { Link } from "@reach/router";
import styled from "styled-components";
//
import Navigation from "./menu";
//
export default ({ ...rest }) => (
  <Toggle initial={false}>
    {({ on, toggle }) => (
      <Wrap mobileActive={on}>
        <Link to="/" title="Eeva-Kaisa Ailus">
          <h1>Eeva-Kaisa Ailus</h1>
        </Link>
        <Navigation mobileActive={on} onClick={toggle} {...rest} />
      </Wrap>
    )}
  </Toggle>
);
//

const Wrap = styled.header`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  // position: absolute;
  z-index: 2000;

  a {
    a:hover,
    &.active {
      text-decoration: none;
      color: #c80000;
    }
  }
`;
